<template>
  <div class="ev2-box pedidos">
    <div class="header">
      <div class="hcol1">
        <div class="title">Últimos registros</div>
        <div class="subtitle">Histórico mais recente</div>
      </div>
      <div class="hcol2">
        <i class="fa fa-refresh m-t-sm m-r" @click="load" />
        <erp-input v-model="busca" class="sl-input input-sm" placeholder="Buscar" :before="[{icon: 'search', handler () {}}]" />
        <erp-select v-model="buscaTipo" :options="buscaTipoOpts" class="sl-input input-sm m-l-sm" />
      </div>
    </div>
    <div class="body">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props" :props="props">
          <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td class="td-limit" key="leiloeiro" :props="props">
            <div v-if="props.row.solicitante" class="flex items-center cursor-pointer" @click="$router.push({name: 'fenaju.clientes.cliente', params: {id: props.row.id}})">
              <div class="app-person-avatar m-r-sm flex justify-center full-width">
                <img v-if="props.row.solicitante && props.row.solicitante.photo" :src="props.row.solicitante.photo">
                <div v-else class="img-fake" style="background-color: #399B9F">{{ firstLettersName(props.row.solicitante.name) }}</div>
              </div>
              <div class="m-l-xs flex items-center column">
                <div>
                  <div>{{props.row.solicitante.name}}</div>
<!--                  <div class="font-10 text-grey-5">{{props.row.matriculas}}</div>-->
                </div>
              </div>
            </div>
          </u-td>
          <u-td class="" key="dominio" :props="props">
            {{props.row.dominio}}
          </u-td>
          <u-td class="" key="status" :props="props">
            <dominio-status :dominio="props.row" />
          </u-td>
          <u-td class="text-right" key="options" :props="props">
            <div class="cursor-pointer">
              <div class="text-right no-select">Ações <i class="fa fa-chevron-down text-grey-5 font-10" /></div>
              <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
                <ul>
                  <menu-options-item close label="Abrir" @click="$router.push({name: 'fenaju.clientes.cliente', params: {id: props.row.id}})" />
                  <menu-options-item close label="Editar" @click="editarCliente(props.row)" />
                </ul>
              </u-popover>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <div class="footer">
      <div class="text-center">
        <u-btn @click="$router.push({name: 'fenaju.clientes.lista'})" class="f-btn" no-caps label="Ver tudo" />
        <u-btn @click="criarCliente" class="m-l f-btn bg-green" no-caps label="Novo Registro" />
      </div>
    </div>
  </div>
</template>

<script>
import {list} from "@/domain/fenaju/services"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue";
import DominioStatus from "components/fenaju/components/include/DominioStatus.vue";
import criarClienteWindow from "components/fenaju/components/window/registroWindow";

let filters = {
  id: null,
}

const listName = 'table.fenaju.pedidosDash'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Leiloeiro', name: 'leiloeiro', active: true, sortable: true, ordering: 1},
  {label: 'Domínio', name: 'dominio', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
], 0)
export default {
  name: "Pedidos",
  components: {
    DominioStatus,
    MenuOptionsItem,
    ErpSelect,
    ErpInput,
    UTable, UTr, UTh, UTd, UPopover
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      busca: null,
      buscaTipo: 'pendentes',
      buscaTipoOpts: [
        {label: 'Com pendência', value: 'pendentes'},
        {label: 'Todos', value: 'todos'},
      ],
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      // if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
      //   extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      // }

      if (this.buscaTipo === 'pendentes') {
        extraFilters.push(`&status=0,1,2,3,90,101`)
      }

      //this.table.filters.solicitante && extraFilters.push(`&solicitante=${this.table.filters.solicitante.id || this.table.filters.pessoa}`)
      //this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    criarClienteWindow,
    criarCliente () {
      this.criarClienteWindow(null, null)
    },
    editarCliente (c) {
      this.criarClienteWindow(c.id)
    }
  }
}
</script>
