<template>
  <div :style="'color: ' + Status.status[dominio.status].color">
    {{Status.status[dominio.status].label}} <i v-if="dominio.status === Status.STATUS_AGUARDANDO_REGISTRO" class="fa fa-timer text-warning" />
  </div>
</template>

<script>
import * as Status from "@/domain/fenaju/helpers"
export default {
  name: "DominioStatus",
  props: ['dominio'],
  computed: {
    Status () {
      return Status
    }
  }
}
</script>
